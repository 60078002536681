import moment from 'moment'
import { Component, Vue } from 'vue-property-decorator'
import { AccessLevel } from '../../enums/access-levels.enum'
import { Layout } from '../../enums/layouts.enum'
import { TwoFactorAuthenticationMethod } from '../../enums/two-factor-methods.enum'
import CompanyUser from '../../models/company-user.model'
import languages from '@/bootstrap/global/languages'
import { Module } from '../../enums/modules.enum'
import Container, { Service } from 'typedi'
import SecurityContext from '../../services/security-context.service'
import ErrorHandlerService from '../../services/error-handler.service'
import { EventStatus } from '../../enums/event-status.enum'
import { mapState } from 'vuex'
import { ScanModeEnum } from '@/modules/level-two/enums/scan-mode.enum'

@Service()
@Component({
  name: 'EventCreation'
})
export default class EventCreation extends Vue {
  eventTypeItems: string[] = [
    'Appearance or Signing',
    'Attraction',
    'Camp, Trip, or Retreat',
    'Class, Training, or Workshop',
    'Concert or Performance',
    'Conference',
    'Convention',
    'Dinner or Gala',
    'Festival or Fair',
    'Game or Competition',
    'Meeting or Networking Event',
    'Other',
    'Party or Social Gathering',
    'Race or Endurance Event',
    'Rally',
    'Screening',
    'Seminar or Talk',
    'Tour',
    'Tournament',
    'Tradeshow, Consumer Show, or Expo'
  ];

  eventCategoryItems: string[] = [
    'Auto, Boat & Air',
    'Business & Professional',
    'Charity & Causes',
    'Community & Culture',
    'Family & Education',
    'Fashion & Beauty',
    'Film, Media & Entertainment',
    'Food & Drink',
    'Government & Politics',
    'Health & Wellness',
    'Hobbies & Special Interest',
    'Home & Lifestyle',
    'Music',
    'Other',
    'Performing & Visual Arts',
    'Religion & Spirituality',
    'School Activities',
    'Science & Technology',
    'Seasonal & Holiday',
    'Sports & Fitness',
    'Travel & Outdoor'
  ];
}
