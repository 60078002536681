import { Component, Vue, Ref, Prop, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import moment from 'moment'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'
import { ValidationObserver } from 'vee-validate'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'

@Component({
  name: 'GtrEditEventModal',
  computed: mapState('event', ['event', 'eventDupe'])
})
export default class GtrEditEventModal extends GtrSuper {
  @Prop({ required: true, type: Boolean, default: false })
  modalOpen: boolean | undefined

  @Ref()
  readonly addressField!: InstanceType<typeof VuetifyGoogleAutocomplete>

  @Ref()
  readonly form!: InstanceType<typeof HTMLFormElement>

  @Ref()
  readonly observer!: InstanceType<typeof ValidationObserver>

  eventTypeItems: string[] = [
    'Appearance or Signing',
    'Attraction',
    'Camp, Trip, or Retreat',
    'Class, Training, or Workshop',
    'Concert or Performance',
    'Conference',
    'Convention',
    'Dinner or Gala',
    'Festival or Fair',
    'Game or Competition',
    'Meeting or Networking Event',
    'Other',
    'Party or Social Gathering',
    'Race or Endurance Event',
    'Rally',
    'Screening',
    'Seminar or Talk',
    'Tour',
    'Tournament',
    'Tradeshow, Consumer Show, or Expo'
  ];

  eventCategoryItems: string[] = [
    'Auto, Boat & Air',
    'Business & Professional',
    'Charity & Causes',
    'Community & Culture',
    'Family & Education',
    'Fashion & Beauty',
    'Film, Media & Entertainment',
    'Food & Drink',
    'Government & Politics',
    'Health & Wellness',
    'Hobbies & Special Interest',
    'Home & Lifestyle',
    'Music',
    'Other',
    'Performing & Visual Arts',
    'Religion & Spirituality',
    'School Activities',
    'Science & Technology',
    'Seasonal & Holiday',
    'Sports & Fitness',
    'Travel & Outdoor'
  ];

  data () {
    return {
      submitting: false,
      newEvent: {
        name: null,
        category: null,
        type: null,
        event_identifier: null,
        banner: null,
        favicon: null,
        newBanner: null,
        newFavicon: null,
        uploadNewFavicon: false,
        uploadNewBanner: false,
        address: null,
        active_start_date: null,
        active_end_date: null,
        event_start_date: null,
        event_end_date: null,
        timezone: null,
        latitude: null,
        longitude: null,
        address_data: null,
        uuid: this.$route.params.uuid
      },
      event_uuid: this.$route.params.event_uuid,
      hideDetails: true,
      duplicateEvent: false,
      imagePreview: null,
      iconPreview: null
    }
  }

  @Watch('modalOpen')
  async loadEventInfo (isOpen: boolean) {
    if (isOpen) {
      const event = this.$store.state.event.event
      if (event) {
        this.$data.newEvent = this._.cloneDeep(event)
        this.$data.imagePreview = event.banner
        this.$data.iconPreview = event.favicon
      } else {
        await this.fetchEvent()
        if (this.$store.state.event.event) {
          this.$data.newEvent = this._.cloneDeep(this.$store.state.event.event)
          this.$data.imagePreview = this.$store.state.event.event.banner
          this.$data.iconPreview = this.$store.state.event.event.favicon
        }
      }
      this.$data.newEvent.active_start_date = new Date(this.convertFromUtcToEventTimezone(this.$data.newEvent.active_start_date))
      this.$data.newEvent.active_end_date = new Date(this.convertFromUtcToEventTimezone(this.$data.newEvent.active_end_date))
      this.$data.newEvent.event_start_date = new Date(this.convertFromUtcToEventTimezone(this.$data.newEvent.event_start_date))
      this.$data.newEvent.event_end_date = new Date(this.convertFromUtcToEventTimezone(this.$data.newEvent.event_end_date))
    }
  }

  async fetchEvent () {
    try {
      await this.$store.dispatch('event/fetchEvent', this.$route.params.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  onFileUploadChange (file: File, imageType: string) {
    this.$data.loading = true
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (imageType === 'banner') {
        this.$data.imagePreview = reader.result
      } else if (imageType === 'icon') {
        this.$data.iconPreview = reader.result
      }
    }
    this.$data.loading = false
  }

  async handleDuplcateEvent () {
    this.$data.duplicateEvent = true
    try {
      this.$data.loading = true
      const response = await this.$store.dispatch('event/getEventDupe', { event_uuid: this.$route.params.event_uuid })
      this.$data.newEvent = this._.cloneDeep(response)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async handleEditEventView () {
    this.$data.duplicateEvent = false
    const event = this.$store.state.event.event
    this.$data.newEvent = this._.cloneDeep(event)
    this.$data.imagePreview = event.banner
    this.$data.iconPreview = event.favicon
  }

  prefillEventIdentifier (val: string) {
    if (val) {
      const event_identifier = val.replace(/[^0-9a-z-]/gi, '_')
      this.$data.newEvent.event_identifier = event_identifier
    }
  }

  getAddressData (addressData: any) {
    if (addressData) {
      this.$data.newEvent.address_data = addressData
      this.$data.newEvent.latitude = addressData.latitude
      this.$data.newEvent.longitude = addressData.longitude
    }
  }

  handleTimeZone () {
    if (this.$data.newEvent.address_data !== null) {
      this.$data.hideDetails = true
      this.$store.dispatch('event/loadTimezone', { latitude: this.$data.newEvent.latitude, longitude: this.$data.newEvent.longitude }).then((data) => {
        if (data) {
          this.$data.newEvent.timezone = data.data.timeZoneId
        }
      })
    } else {
      if (this.$data.newEvent.address) {
        this.$data.hideDetails = false
      }
    }
  }

  handleBlur () {
    if (this.$data.newEvent.address) {
      this.$data.hideDetails = true
    } else {
      this.$data.hideDetails = false
    }
  }

  async submit () {
    const isValid = await this.observer.validate()
    if (isValid && this.form.validate()) {
      try {
        this.$data.submitting = true
        if (this.$data.newEvent.latitude && this.$data.newEvent.longitude) {
          this.$data.newEvent.latitude = this.$data.newEvent.latitude.toString()
          this.$data.newEvent.longitude = this.$data.newEvent.longitude.toString()
        }
        const logo = this.$data.newEvent.newBanner
        if (logo instanceof File === true) {
          this.$data.newEvent.uploadNewBanner = true
          this.$data.newEvent.banner = logo
        }
        const favicon = this.$data.newEvent.newFavicon
        if (favicon instanceof File === true) {
          this.$data.newEvent.uploadNewFavicon = true
          this.$data.newEvent.favicon = favicon
        }
        this.$store.dispatch('common/showLoader', { value: true })
        this.$data.newEvent.company_uuid = this.$route.params.uuid
        const { event_start_date, event_end_date, active_start_date, active_end_date } = this.$data.newEvent
        const eventEditData = Object.assign({}, this.$data.newEvent)
        eventEditData.event_start_date = this.convertFromEventTimezoneToUtc((this as any).$refs.event_start_date.formattedDatetime)
        eventEditData.event_end_date = this.convertFromEventTimezoneToUtc((this as any).$refs.event_end_date.formattedDatetime)
        eventEditData.active_start_date = this.convertFromEventTimezoneToUtc((this as any).$refs.active_start_date.formattedDatetime)
        eventEditData.active_end_date = this.convertFromEventTimezoneToUtc((this as any).$refs.active_end_date.formattedDatetime)
        await this.$store.dispatch('event/editEvent', { event_uuid: this.$route.params.event_uuid, data: eventEditData })
        await this.fetchEvent()
        Container.get(Notification).success('Event successfully edited.')
        this.handleClose()
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
        this.$store.dispatch('common/hideLoader')
      }
    }
  }

  handleClose () {
    this.$emit('modal-closed')
    this.cleanForm()
  }

  cleanForm () {
    // this.$data.newEvent = {
    //   name: null,
    //   event_identifier: null,
    //   banner: null,
    //   newBanner: null,
    //   address: null,
    //   active_start_date: null,
    //   active_start_date_menu: false,
    //   active_end_date: null,
    //   active_end_date_menu: false,
    //   event_start_date: null,
    //   event_start_date_menu: false,
    //   event_end_date: null,
    //   event_end_date_menu: false,
    //   timezone: null,
    //   latitude: null,
    //   longitude: null,
    //   address_data: null
    // }
    this.observer.reset()
  }

  private async convertUrlToFile (url: string) {
    const response = await fetch(url, { mode: 'no-cors' })
    const blob = await response.blob()
    const file = new File([blob], 'image.jpg', { type: blob.type })
    return file
  }
}
