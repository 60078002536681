import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import {
  DuplicateFormPageCheckboxInput, DuplicateFormPageInput,
  IDuplicateFormPage,
  IEventDetails, IFormPageSection,
  IPreviewSize
} from 'gtr-types/event-creation'
import { ValidationObserver } from 'vee-validate'
import Container from 'typedi'
import EventCreation from '@/modules/common/components/mixins/event-creation.mixin'
import _ from 'lodash'
import { format } from 'date-fns'

@Component<GtrEventDuplicationWizard>({
  name: 'gtr-event-duplication-wizard'
})
export default class GtrEventDuplicationWizard extends EventCreation {
  /**
   * ref to the address field.
   */
  @Ref()
  readonly addressField!: InstanceType<typeof VuetifyGoogleAutocomplete>

  /**
   * ref to the image fields.
   */
  @Ref()
  readonly image!: InstanceType<typeof HTMLInputElement>[];

  /**
   * ref to the validation observer.
   */
  @Ref()
  readonly observer!: InstanceType<typeof ValidationObserver>

  /**
   * The width for the modal.
   */
  @Prop({ required: false, type: Number, default: 450 })
  width: number | undefined;

  /**
   * The value to show/hide the modal.
   */
  @Prop({ required: true, type: Boolean, default: false })
  value: boolean | undefined;

  /**
   * @memberof GtrEventDuplicationWizard
   * @name event_uuid
   * @type {string}
   * @description The event uuid.
   */
  event_uuid: string = this.$route.params.event_uuid;

  /**
   * @memberof GtrEventDuplicationWizard
   * @name progress
   * @type {number}
   * @description the form progress.
   */
  progress = 1;

  furthestProgress = 1

  /**
   * @memberof GtrEventDuplicationWizard
   * @name loading
   * @type {boolean}
   * @description loading state.
   */
  loading = false;

  hasUserInputAddressData = false

  /**
   * @memberof GtrEventDuplicationWizard
   * @name eventDetails
   * @type {IEventDetails}
   * @description the data to send to the backend
   */
  eventDetails: IEventDetails = {
    name: null,
    category: null,
    type: null,
    event_identifier: null,
    banner: null,
    favicon: null,
    newBanner: null,
    newFavicon: null,
    uploadNewFavicon: false,
    uploadNewBanner: false,
    address: null,
    active_start_date: null,
    active_end_date: null,
    event_start_date: null,
    event_end_date: null,
    timezone: null,
    latitude: null,
    longitude: null,
    address_data: null,
    uuid: this.event_uuid,
    company_uuid: null
  };

  /**
   * @memberof GtrEventDuplicationWizard
   * @name registrationOptions
   * @description the data to send to the backend
   */
  registrationOptions: string[] = []

  /**
   * @memberof GtrEventDuplicationWizard
   * @name leadRetrievalOptions
   * @description the data to send to the backend
   */
  leadRetrievalOptions: string[] = []

  /**
   * @memberof GtrEventDuplicationWizard
   * @name badgePrintingOptions
   * @description the data to send to the backend
   */
  badgePrintingOptions: string[] = []

  /**
   * @memberof GtrEventDuplicationWizard
   * @name platformOptions
   * @description the data to send to the backend
   */
  platformOptions: string[] = []

  /**
   * @memberof GtrEventDuplicationWizard
   * @name options
   * @desciption the options for the duplication wizard.
   */
  get options () {
    return {
      categories: this.eventCategoryItems,
      types: this.eventTypeItems
    }
  }

  /**
   * @memberof GtrEventDuplicationWizard
   * @name formPages
   * @type {Array<IDuplicateFormPage>}
   * @description list of form pages for the duplication wizard.
   */
  formPages: Array<IDuplicateFormPage> = [
    [
      {
        title: 'Event Info',
        description: null,
        key: 'event',
        inputs: [
          {
            label: 'Event Title',
            description: null,
            type: 'text',
            value: '',
            key: 'name',
            rules: 'required'
          }, {
            label: 'Location',
            description: null,
            type: 'address',
            value: '',
            key: 'address_data',
            rules: 'required'
          }, {
            label: 'Event Category',
            description: null,
            type: 'select',
            value: '',
            key: 'category',
            options: 'categories',
            rules: 'required'
          }, {
            label: 'Event Type',
            description: null,
            type: 'select',
            options: 'types',
            value: '',
            key: 'type',
            rules: 'required'
          }, {
            label: 'Event',
            description: null,
            type: 'daterange',
            key: 'event',
            fromValue: null,
            toValue: null,
            fromRules: 'required|isSameOrBeforeDate:@event_end',
            toRules: 'required|isSameOrAfterDate:@event_start'
          }, {
            label: 'Active',
            description: null,
            type: 'daterange',
            key: 'active',
            fromValue: null,
            toValue: null,
            fromRules: 'required|isSameOrBeforeDate:@active_end',
            toRules: 'required|isSameOrAfterDate:@active_start'
          }
        ]
      }
    ], [
      {
        title: 'Images',
        description: null,
        key: 'event',
        inputs: [
          {
            rules: 'image:image/png,image/jpeg,image/bmp|image_size_2mg',
            label: 'Banner Image',
            description: 'My banner image',
            type: 'image',
            key: 'banner',
            ref: 'banner',
            value: null,
            previewSize: [307, 123]
          }, {
            rules: 'image:image/png,image/jpeg,image/bmp|image_size_2mg',
            label: 'Event Icon',
            description: null,
            type: 'image',
            key: 'favicon',
            ref: 'favicon',
            value: null,
            previewSize: [127]
          }
        ]
      }
    ], [
      {
        title: 'Registration',
        description: 'Select the registration items to copy over to the new event',
        key: 'registration',
        inputs: [
          {
            label: 'Form',
            description: null,
            type: 'checkbox',
            value: false,
            key: 'registration_form',
            requires: ['option_groups'],
            allows: ['badges']
          }, {
            label: 'Option Groups',
            description: null,
            type: 'checkbox',
            value: false,
            key: 'option_groups',
            requires: ['pricing_tiers'],
            allows: ['registration_form', 'badges']
          }, {
            label: 'Site Design',
            description: null,
            type: 'checkbox',
            value: false,
            key: 'registration_design'
          }, {
            label: 'Promo Codes',
            description: null,
            type: 'checkbox',
            value: false,
            key: 'promo_codes'
          }, {
            label: 'Pricing Tiers',
            description: null,
            type: 'checkbox',
            value: false,
            key: 'pricing_tiers',
            allows: ['option_groups']
          }, {
            label: 'Content Pages',
            description: null,
            type: 'checkbox',
            value: false,
            key: 'content_pages'
          }, {
            label: 'Settings',
            description: null,
            type: 'checkbox',
            value: false,
            key: 'registration_settings'
          }
        ]
      }
    ], [
      {
        title: 'Lead Retrieval',
        description: null,
        key: 'lead_retrieval',
        inputs: [
          {
            label: 'Settings',
            description: null,
            type: 'checkbox',
            key: 'lr_settings',
            value: false
          },
          {
            label: 'Design',
            description: null,
            type: 'checkbox',
            key: 'lr_design',
            value: false
          }
        ]
      }, {
        title: 'Badge Printing',
        description: null,
        key: 'badge_printing',
        inputs: [
          {
            label: 'Badge Templates and Settings',
            description: null,
            type: 'checkbox',
            value: false,
            key: 'badges',
            requires: ['registration_form', 'option_groups']
          }
        ]
      }, {
        title: 'Platform Level',
        description: null,
        key: 'platform',
        inputs: [
          {
            label: 'Emails',
            description: null,
            type: 'checkbox',
            value: false,
            key: 'emails'
          }, {
            label: 'Reports',
            description: null,
            type: 'checkbox',
            value: false,
            key: 'reports'
          }, {
            label: 'Integrations',
            description: null,
            type: 'checkbox',
            value: false,
            key: 'integrations',
            requires: ['registration_form', 'option_groups']
          }
        ]
      }
    ]
  ]

  async mounted () {
    if (this.observer) {
      this.observer.validate()
    }
    this.eventDetails.company_uuid = this.$route.params.uuid
  }

  /**
   * loop through image refs and activate the file upload
   * @memberof GtrEventDuplicationWizard
   * @name activateUpload
   */
  activateUpload (label: string): void {
    this.image.forEach((input) => {
      if (input.name === label) {
        input.click()
      }
    })
  }

  /**
   * storage for base64 encoded image previews.
   * @memberof GtrEventDuplicationWizard
   * @name imagePreviews
   */
  imagePreviews = {
    banner: '',
    favicon: ''
  }

  /**
   * is the form valid
   */
  valid = true

  /**
   * The duplication steps
   */
  duplicationSteps: Array<Record<string, any>> = [
    {
      label: 'Event Info'
    }, {
      label: 'Images'
    }, {
      label: 'Registration'
    }, {
      label: 'Additional Products'
    }
  ]

  /**
   * the setter for the computed address data
   * @memberof GtrEventDuplicationWizard
   * @name addressData
   */
  set addressData (value: any) {
    this.eventDetails.address_data = value
    this.eventDetails.latitude = value.latitude.toString()
    this.eventDetails.longitude = value.longitude.toString()
  }

  /**
   * the getter for the computed address data
   * @memberof GtrEventDuplicationWizard
   * @name addressData
   */
  get addressData () {
    return this.eventDetails.address_data
  }

  /**
   * @memberof GtrEventDuplicationWizard
   * @name nextButtonText
   * @description the next button verbiage.
   */
  get nextButtonText (): string {
    switch (this.progress) {
      case 4:
        return 'Duplicate'
      default:
        return 'Next'
    }
  }

  /**
   * Map all inputs to key value pairs
   * @memberof GtrEventDuplicationWizard
   * @method mapInputs
   */
  private mapInputs (input) {
    const { key, type } = input
    if (type !== 'daterange') {
      return {
        key,
        value: input.value,
        type: input.type
      }
    } else {
      return {
        key,
        from: input.fromValue,
        to: input.toValue,
        type: input.type
      }
    }
  }

  /**
   * Map all inputs to key value pairs
   * @memberof GtrEventDuplicationWizard
   * @method mapPageSections
   */
  private mapPageSections ({ key, inputs }) {
    return {
      [key]: inputs.map(this.mapInputs)
    }
  }

  /**
   * Map all pages to sections
   * @memberof GtrEventDuplicationWizard
   */
  private flatMapPages (page) {
    return page.map(this.mapPageSections)
  }

  /**
   * Merge all inputs by section key
   * @memberof GtrEventDuplicationWizard
   */
  private mergeInputsByKey (sections) {
    const inputsByKey = {
      event: [],
      registration: [],
      lead_retrieval: [],
      badge_printing: [],
      platform: []
    }

    sections.forEach((section) => {
      Object.entries(section).forEach(([key, inputs]) => {
        inputsByKey[key] = inputsByKey[key].concat(inputs)
      })
    })

    return inputsByKey
  }

  /**
   * Get all form inputs and process them into an object.
   * @memberof GtrEventDuplicationWizard
   */
  get allFormInputs () {
    const inputs = this.formPages
      .flatMap(this.flatMapPages)
    const inputsByKey = this.mergeInputsByKey(inputs)
    return inputsByKey
  }

  /**
   * payload computed property
   * @memberof GtrEventDuplicationWizard
   */
  get payload (): IEventDetails {
    return {
      ...this.eventDetails,
      data_to_duplicate: [
        ...this.registrationOptions,
        ...this.leadRetrievalOptions,
        ...this.badgePrintingOptions,
        ...this.platformOptions
      ]
    }
  }

  /**
   * update the address data.
   * @memberof GtrEventDuplicationWizard
   * @method updateAddressData
   */
  updateAddressData (addressData: any) {
    this.addressData = addressData
  }

  handleAddressDataUserInput () {
    this.$data.hasUserInputAddressData = true
  }

  /**
   * Debounced function to get the timezone for the event address.
   * @memberof GtrEventDuplicationWizard
   * @returns {Promise<void>}
   */
  getTimezoneAtAddress = _.debounce(async () => {
    try {
      if (this.eventDetails.address_data !== null) {
        const { latitude, longitude } = this.eventDetails
        const { data: timezone } = await this.$store.dispatch('event/loadTimezone', {
          latitude,
          longitude
        })
        if (timezone) {
          this.eventDetails.timezone = timezone.timeZoneId
        }
      }
    } catch (error) {
      Container.get(Notification).error('There was an error retrieving timezone data')
    }
  }, 1000)

  /**
   * @memberof GtrEventDuplicationWizard
   * @method onCloseOrCancel
   * @description Emits the close event, to close the modal.
   */
  onCloseOrCancel (): void {
    this.$emit('close')
    this.resetForm()
  }

  /**
   * @memberof GtrEventDuplicationWizard
   * @method submit
   * @description Submits the dupliate form data.
   */
  async submit (): Promise<void> {
    try {
      const eventData = await this.$store.dispatch(
        'event/dupeEvent',
        {
          event_uuid: this.event_uuid,
          event: this.payload
        })
      const eventDataMain = eventData.data
      const timer = ms => new Promise(resolve => setTimeout(resolve, ms))
      let isEventActive = eventDataMain.status === 'ACTIVE'
      while (!isEventActive) {
        const result = await this.$store.dispatch('event/getEventDupe', { event_uuid: eventDataMain.uuid })
        if (result.status === 'ACTIVE') {
          isEventActive = true
        } else if (result.status === 'FAILED_TO_CREATE') {
          Container.get(ErrorHandlerService).error('Event failed to duplicate')
          return
        }
        await timer(2000)
      }

      await this.$router.push({ name: 'level-two.company.events' })
      this.$emit('close')
    } catch (err) {
      Container.get(Notification).error('There was an error duplicating the event')
    }
  }

  /**
   * @memberof GtrEventDuplicationWizard
   * @method resetForm
   * @description Resets the form to the initial state.
   */
  resetForm (): void {
    this.eventDetails = {
      name: null,
      category: null,
      type: null,
      event_identifier: null,
      banner: null,
      favicon: null,
      newBanner: null,
      newFavicon: null,
      uploadNewFavicon: false,
      uploadNewBanner: false,
      address: null,
      active_start_date: null,
      active_end_date: null,
      event_start_date: null,
      event_end_date: null,
      timezone: null,
      latitude: null,
      longitude: null,
      address_data: null,
      uuid: this.event_uuid,
      company_uuid: null
    }

    this.registrationOptions = []

    this.leadRetrievalOptions = []

    this.badgePrintingOptions = []

    this.platformOptions = []
  }

  /**
   * Handler for file upload change event.
   * @memberof GtrEventDuplicationWizard
   * @param event
   * @param ref
   */
  onFileUploadChange (event: Event, inputObject): void {
    const target = event.target as HTMLInputElement
    const reader = new FileReader()
    if (target === null) return
    const file = (target.files as FileList)[0]
    reader.readAsDataURL(file)
    reader.onload = () => {
      this.imagePreviews[inputObject.ref] = reader.result
      inputObject.value = file
    }
  }

  /**
   * process inputs that are of type image.
   * @memberof GtrEventDuplicationWizard
   * @method processImageInputs
   */
  processImageInputs (input: any): void {
    if (input.value) {
      if (input.key === 'banner') {
        this.eventDetails.banner = input.value
        this.eventDetails.newBanner = input.value
        this.eventDetails.uploadNewBanner = true
      } else {
        this.eventDetails.favicon = input.value
        this.eventDetails.newFavicon = input.value
        this.eventDetails.uploadNewFavicon = true
      }
    }
  }

  /**
   * This will process inputs that are of type daterange.
   * @memberof GtrEventDuplicationWizard
   * @method processDateRangeInputs
   */
  processDateRangeInputs (input: {
    key: string;
    from: Date | null;
    to: Date | null;
    type: string;
  }): void {
    if (input.from === null || input.to === null) return
    if (input.key === 'event') {
      this.eventDetails.event_start_date = format(input.from, 'yyyy-MM-dd HH:mm')
      this.eventDetails.event_end_date = format(input.to, 'yyyy-MM-dd HH:mm')
    } else {
      this.eventDetails.active_start_date = format(input.from, 'yyyy-MM-dd HH:mm')
      this.eventDetails.active_end_date = format(input.to, 'yyyy-MM-dd HH:mm')
    }
  }

  /**
   * Process event images inputs
   * @memberof GtrEventDuplicationWizard
   * @method processEventImages
   */
  processEventImages (inputs: Array<any>): void {
    const images = inputs.filter((input) => input.type === 'image')
    images.forEach(this.processImageInputs)
  }

  /**
   * Process inputs
   * @memberof GtrEventDuplicationWizard
   * @method processSelectedOptions
   */
  processSelectedOptions (inputs: Array<any>, options: string[]): void {
    options.length = 0
    inputs.forEach(input => {
      if (input.value) {
        options.push(input.key)
      }
    })
  }

  /**
   * Process event inputs.
   * @memberof GtrEventDuplicationWizard
   * @method processEventDetails
   */
  processEventDetails (inputs: Array<any>): void {
    const dates = inputs.filter((input) => input.type === 'daterange')
    const details = inputs.filter((input) => input.type !== 'daterange')

    dates.forEach(this.processDateRangeInputs)
    details.forEach((input) => {
      this.eventDetails[input.key] = input.value
      if (input.key === 'name') {
        this.eventDetails.event_identifier = input.value.replace(/[^0-9a-z-]/gi, '_')
      }
    })
    this.eventDetails.address = this.eventDetails.address_data
  }

  async updateSubmissionData () {
    switch (this.progress) {
      case 1: {
        // store event details.
        this.processEventDetails(this.allFormInputs.event)
        this.valid = await this.observer.validate()
        if (!this.valid) {
          return false
        }
        break
      }
      case 2:
        // store images.
        this.processEventImages(this.allFormInputs.event)
        break
      case 3:
      case 4:
        // Combine cases 3 and 4 since options on one page might affect options on the other.
        // store module options to be duplicated
        this.processSelectedOptions(this.allFormInputs.registration, this.registrationOptions)
        this.processSelectedOptions(this.allFormInputs.lead_retrieval, this.leadRetrievalOptions)
        this.processSelectedOptions(this.allFormInputs.badge_printing, this.badgePrintingOptions)
        this.processSelectedOptions(this.allFormInputs.platform, this.platformOptions)
        break
      case 5:
        this.$emit('close')
        break
      default:
        // if progress is greater than 5, then we are done.
        // TODO(z): this should also handle the svg page.
    }
    return true
  }

  /**
   * Handler for click event on the next button.
   * @memberof GtrEventDuplicationWizard
   * @method handleClickNext
   */
  async handleClickNext (): Promise<void> {
    const proceed = await this.updateSubmissionData()
    if (this.progress === 4) {
      this.submit()
    }
    if (proceed) {
      this.progress++
    }
  }

  /**
   * Handler for click event on the cancel button.
   * @memberof GtrEventDuplicationWizard
   * @method handleClickCancel
   * @returns {void}
   */
  handleClickCancel (): void {
    this.onCloseOrCancel()
  }

  /**
   * creates an object to be applied for a style attribute.
   * @memberof GtrEventDuplicationWizard
   * @method setSize
   * @param {IPreviewSize} - width height pair.
   * @return {Object} the object to be applied to the style attribute.
   */
  setSize ([width, h]: IPreviewSize, unit = 'px'): { width: string; height: string } {
    const height = h ?? width
    return {
      width: `${width}${unit}`,
      height: `${height}${unit}`
    }
  }

  /**
   * Gets all field labels.
   * @returns object - keys are field keys, and values are strings of field labels
   */
  get fieldLabels () {
    return this.$data.formPages.reduce((accumulator, currentPage: IDuplicateFormPage) => {
      currentPage.forEach((currentSection: IFormPageSection) => {
        currentSection.inputs.forEach((input: DuplicateFormPageInput) => {
          accumulator[input.key] = input.label
        })
      })
      return accumulator
    }, {})
  }

  /**
   * Gets all component requirements for display.
   * @returns object - keys are field keys, and values are comma delimited strings of field labels
   */
  get componentDependencies (): string[] {
    return this.$data.formPages.reduce((accumulator, currentPage: IDuplicateFormPage) => {
      currentPage.forEach((currentSection: IFormPageSection) => {
        currentSection.inputs.forEach((input: DuplicateFormPageInput) => {
          if (input.type === 'checkbox' && input.requires?.length) {
            const requiredFields = input.requires.map((inputKey: string) => {
              return this.fieldLabels[inputKey]
            })
            accumulator[input.key] = requiredFields.join(', ')
          }
        })
      })
      return accumulator
    }, {})
  }

  /**
   * Loops through string array and sets value of checkboxes whose names correspond to its elements.
   * @memberof GtrEventDuplicationWizard
   * @method setComponentCheckboxes
   * @param fieldKeyArray - array containing field name strings
   * @param newValue - boolean
   * @return void
   */
  setComponentCheckboxes (fieldKeyArray: string[], newValue: boolean) {
    fieldKeyArray.forEach((fieldKey: string) => {
      this.$data.formPages.forEach((page: IDuplicateFormPage) => {
        page.forEach((section: IFormPageSection) => {
          section.inputs.forEach((input: DuplicateFormPageInput) => {
            if (input.type === 'checkbox' && input.key === fieldKey) {
              if (input.value !== newValue) {
                input.value = newValue
                this.handleComponentCheckboxChange(input) // recursively apply setting to related checkboxes
              }
            }
          })
        })
      })
    })
  }

  /**
   * Handles selecting required components if a component is selected or deselecting required components if it is deselected.
   * @memberof GtrEventDuplicationWizard
   * @method handleComponentCheckboxChange
   * @param activeInput - input being changed
   * @return void
   */
  handleComponentCheckboxChange (activeInput: DuplicateFormPageCheckboxInput) {
    if (activeInput.value) {
      // If the box is checked, also check all required boxes.
      if (activeInput.requires?.length) {
        this.setComponentCheckboxes(activeInput.requires, true)
      }
    } else {
      // If the box is unchecked, also uncheck all allowed boxes.
      if (activeInput.allows?.length) {
        this.setComponentCheckboxes(activeInput.allows, false)
      }
    }
  }

  @Watch('progress', { immediate: true })
  onProgressChange (newValue: number) {
    if (newValue > this.$data.furthestProgress) {
      this.$data.furthestProgress = newValue
    }
  }
}
